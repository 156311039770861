import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { Move, User } from 'react-feather'

import { Equipment } from './Equipment'
import { Provisions, ProvisionsProps } from './Provisions'

export interface Props {
  equipmentList?: string[]
  equipmentTitle?: string
  image?: ImageProps
  pax?: string
  provisionsList?: ProvisionsProps[]
  provisionsTitle?: string
  size?: string
  title?: string
}

export const Room = memo(function Room({
  equipmentList,
  equipmentTitle,
  image,
  pax,
  provisionsList,
  provisionsTitle,
  size,
  title,
}: Props) {
  return (
    <Container row wrap stretch>
      <LeftSide dial={4}>
        {pax || size ? (
          <FadeInUp>
            <Info dial={4} row wrap>
              {pax ? (
                <Pax dial={4} row>
                  <User />
                  {pax}
                </Pax>
              ) : null}
              {size ? (
                <Size dial={4} row>
                  <Move />
                  {size}
                </Size>
              ) : null}
            </Info>
          </FadeInUp>
        ) : null}
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        <Provisions list={provisionsList} title={provisionsTitle} />
        <Equipment list={equipmentList} title={equipmentTitle} />
      </LeftSide>
      <RightSide>{image ? <Image {...image} /> : null}</RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  position: relative;
  &:first-of-type {
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      z-index: 2;
    }
    &:before {
      width: 2.875rem;
      height: 2.875rem;
      background: ${({ theme }) => theme.colors.variants.neutralLight4};
      top: -1.4375rem;
    }
    &:after {
      width: 1.125rem;
      height: 1.125rem;
      background: ${({ theme }) => theme.colors.variants.primaryLight1};
      top: -0.5625rem;
    }
  }
  &:nth-of-type(even) {
    > div {
      &:first-of-type {
        order: 2;
      }
    }
  }
`

const LeftSide = styled(FlexBox)`
  width: 50%;
  padding: 5.5rem 9vw 5.5rem 11.667vw;

  @media (max-width: 1023px) {
    width: 100%;
    order: 2;
    padding: 3rem 1.9375rem;
  }
`

const Info = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  font-weight: 800;
  letter-spacing: 0.0688rem;
  line-height: 0.875rem;
  margin-bottom: 1.3125rem;
  text-transform: uppercase;

  svg {
    width: auto;
    height: 1.625rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    opacity: 0.2;
    margin-right: 0.75rem;
  }
`

const Pax = styled(FlexBox)`
  margin-right: 1.875rem;
`

const Size = styled(FlexBox)``

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3.25rem;

  @media (max-width: 1023px) {
    font-size: 1.875rem;
    line-height: 2.4375rem;
  }
`

const RightSide = styled(FlexBox)`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding-bottom: 42%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding-bottom: 50%;
  }
`
